import {Box, Grid} from "@mui/material";
import MenuItemCard from "./menuItems";
import React from "react";

const MenuList = ({menuItems}) => {

    return (
        <Box>
            <Grid container sx={{justifyItems: 'center', justifyContent: 'center'}} spacing={1.5} centered='true'>
                {menuItems.map((item, index) => (
                    <Grid minHeight={205} item key={index}>
                        <MenuItemCard item={item}/>
                    </Grid>
                ))}
            </Grid>
        </Box>)
};

export default MenuList