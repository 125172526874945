import React from 'react'


import styles from './NotFound.module.scss'
import { Link } from 'react-router-dom'

const NotFound = () => {
    return (
        <section class={styles.page_404}>
            <div class={styles.container}>
                <div class={styles.row}>
                    <div>
                        <div class={styles.text_center}>
                            <div class={styles.four_zero_four_bg}>
                                <h1 class={styles.text_center}>404</h1>


                            </div>

                            <div class={styles.contant_box_404}>
                                <h3 class={styles.h2}>
                                    Look like you're lost
                                </h3>

                                <p>the page you are looking for not avaible!</p>
                                <Link to={"/"} class={styles.link_404}>Go to Home</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default NotFound