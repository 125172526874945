import { Tab, Tabs } from "@mui/material";

const CategoryTabs = ({ categories, tabValue, handleChange }) => {


    return (
        <Tabs
            indicatorColor='none'
            textColor="primary"
            variant='fullWidth'
            value={tabValue}
            onChange={handleChange}
            sx={{
                px: 0.5,
                '& .MuiTab-root:focus': {
                    color: 'white'
                },
                '& .Mui-selected': {
                    backgroundColor: 'rgba(0, 167, 189, 0.23)',
                    color: 'white'
                }
            }}
            centered={true}>
            {categories.length && categories.map((category, index) => (
                <Tab key={index} label={category}
                    sx={{
                        color: 'white',
                        fontWeight: 'bold',
                        border: 2,
                        borderStyle: 'dashed',
                        borderRadius: '20px',
                        mx: 0.5
                    }} />
            ))}
        </Tabs>
    )
}

export default CategoryTabs