import {Box, Divider} from "@mui/material";
import React, {useEffect, useState} from "react";
import CategoryTabs from "../../components/tabs/categoryTabs";
import SubCategoryTabs from "../../components/tabs/subCategoryTabs";
import MenuList from "../../components/menu/menuList";
import {API_URL} from "../../config/api";
import axios from "axios";

export const Menu = () => {

    const [loading, setLoading] = useState(true);
    // const [error, setError] = useState(null);

    const [categories, setCategories] = useState(null)
    const [subCategories, setSubCategories] = useState({})


    const [categoryTabValue, setCategoryTabValue] = useState(0);
    const [subCategoryTabValue, setSubCategoryValue] = useState(0);
    const [menuItems, setMenuItems] = useState([]);
    const [dataItems, setDataItems] = useState({});

    const handleChangeTabCategory = (event, newValue) => {
        setCategoryTabValue(newValue);
        setSubCategoryValue(0);
        if (Object.keys(dataItems).length) {
            const subName = subCategories[categories[newValue]][subCategoryTabValue]
            const items = dataItems[subName] || []
            setMenuItems(items)
        }

    };

    const handleChangeTabSubCategory = (event, newValue) => {
        setSubCategoryValue(newValue);
        if (Object.keys(dataItems).length) {
            const subName = subCategories[categories[categoryTabValue]][newValue]
            const items = dataItems[subName] || []
            setMenuItems(items)
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                console.log(process.env.NODE_ENV)
                const response = await axios.get(API_URL + '/menu');
                const data = response.data

                if (data && Object.keys(data).length) {
                    console.log(data)
                    const categoryNames = Object.keys(data)

                    setCategories(categoryNames)

                    let tmpSub = {}
                    let tmpItems = {}
                    categoryNames.map((category) => {
                        tmpSub[category] = Object.keys(data[category])

                        tmpSub[category].map(subCategory => {
                            tmpItems[subCategory] = data[category][subCategory]
                            return subCategory
                        })

                        return category
                    })

                    setSubCategories(tmpSub)
                    setDataItems(tmpItems)
                    const subName = tmpSub[categoryNames[0]][0]
                    const items = tmpItems[subName] || []
                    setMenuItems(items)
                }
            } catch (error) {
                // setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();

    }, []);

    return (
        <Box>
            {(!loading && categories) && <Box>
                <Box sx={{
                    py: 1,
                    height: 'auto',
                    backgroundColor: 'rgba(19,19,19,0.9)',
                }}>
                    <CategoryTabs
                        categories={categories}
                        tabValue={categoryTabValue}
                        handleChange={handleChangeTabCategory}
                    />
                    <SubCategoryTabs
                        subCategories={subCategories[categories[categoryTabValue]]}
                        tabValue={subCategoryTabValue}
                        handleChange={handleChangeTabSubCategory}
                    />
                </Box>
                <Divider sx={{backgroundColor: 'white', my: 1}}/>
                <MenuList menuItems={menuItems}/>
            </Box>
            }
        </Box>
    );

};
