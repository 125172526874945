import { Box, Tab, Tabs } from "@mui/material"

const SubCategoryTabs = ({ subCategories, tabValue, handleChange }) => {


    return (
        <Box display={'flex'} justifyContent={'center'}>
            <Tabs
                indicatorColor='none'
                value={tabValue}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                sx={{
                    pt: 1,
                    '& .MuiTabScrollButton-root': {
                        backgroundColor: 'rgba(44, 171, 198, 0.3)', width: '30px', color: 'white', m: 0.1, border: 1, borderRadius: '30px'
                    },
                    '& .MuiTab-root:hover': {
                        color: '#007bff',
                        opacity: 1,
                    },
                    '& .MuiTab-root:focus': {
                        boxShadow: '0 0 0 2px rgba(0, 123, 255, 0.2)',
                    },
                }}
            >
                {subCategories.map((subCategory, index) => (
                    <Tab sx={{
                        color: 'white', fontSize: '14px', border: 1, borderRadius: '40%', mx: 0.5
                    }} key={index} label={subCategory} />
                ))}
            </Tabs>
        </Box>
    )
}

export default SubCategoryTabs